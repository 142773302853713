import { ref } from 'vue'
import { api } from '@/main'
import { AiUnit } from '@/api/units'
import { API } from 'aws-amplify'
import { AiSession } from '@/api/sessions'

export const fetchSession = async (sessionId: string): Promise<AiSession> => {
  const { data: session } = await api.sessions.sessionsGetSession({
    session: sessionId,
  })

  return session
}

export const fetchUnit = async (sessionId: string, unitId: string): Promise<AiUnit> => {
  const { data: unit } = await api.units.unitsGetUnit({
    session: sessionId,
    unit: unitId,
  })

  return unit
}

export const fetchHLSPlaylist = async (playlist: string, bucket: string | undefined): Promise<string | undefined> => {
  const videoUrl = ref('')

  if (!playlist || !bucket) return

  try {
    const hlsData = await API.get(
      'pmaCloudDashboardApi',
      '/hls/playlist', {
        queryStringParameters: {
          playlist: playlist,
          bucket: bucket,
        },
      },
    )

    const hlsDataBuff = Buffer.from(hlsData, 'utf-8')
    videoUrl.value = `data:application/x-mpegURL;base64,${hlsDataBuff.toString('base64')}`
  } catch (_) {
    console.log('Stream not found')
  }

  return videoUrl.value
}

export const loadPlaylist = (unit: AiUnit): string => {
  let playlist = ''
  if (unit.unitVideos) {
    playlist = unit.unitVideos[0].substring(1)
  } else {
    throw new Error('Unable to determine playlist url')
  }

  return playlist
}
