import { AiProcedure, AiProcedureStep } from '@/api/procedures'
import { api } from '@/main'
import { useUsersStore } from '@/stores/users/users'

export interface NormalisedStep {
  name: string,
  stepId: string,
  numberMacro: number,

  aiDetectedOutOfOrder: number,
  aiDetectedMissing: number,
  aiDetectedNotDeviated: number,

  outOfOrder: number,
  missing: number,
  notDeviated: number,
}

export const fetchStepsList = async (sessionId: string, unitId: string, procedure?: AiProcedure): Promise<NormalisedStep[]> => {
  if (!procedure) return []
  const { userGroup } = useUsersStore()
  if (!userGroup) return []

  const macroSteps = (procedure.steps as AiProcedureStep[]).filter(step => (step.numberMacro as number) > 0 && step.numberMicro === 0)
  const friendlyNames = macroSteps.map(step => (step.friendlyName as string).replaceAll('"', ''))

  const { data: { steps } } = await api.steps.stepsListSteps({
    session: sessionId,
    unit: unitId,
    pageSize: -1, /*,
    filter: JSON.stringify({ manufacturer: { $in: userGroup } }), */ // FIXME: remove comment whenever manufacturer filter starts working again
  })

  if (!steps) return []

  const normalisedStepsArray: NormalisedStep[] = []
  for (const step of steps) {
    const { numberMacro, numberMicro, status, deviation } = step

    const isOperationalStep = numberMacro as number <= 0 || numberMicro as number <= 0
    if (isOperationalStep) continue

    const isMacroStepStored = normalisedStepsArray.some(normalisedStep => normalisedStep.numberMacro === numberMacro)

    const isStepOutOfOrder = deviation && (status === 'COMPLETE' || status === 'ACTIVE')
    const isStepMissing = deviation && (status === 'INCOMPLETE')
    const isStepWithoutDeviations = !deviation

    if (isMacroStepStored) {
      const macroStepIndex = normalisedStepsArray.findIndex(normalisedStep => normalisedStep.numberMacro === numberMacro)
      const stepByIndex = normalisedStepsArray[macroStepIndex]

      if (isStepOutOfOrder) {
        stepByIndex.outOfOrder++
        stepByIndex.aiDetectedOutOfOrder++
      }

      if (isStepMissing) {
        stepByIndex.missing++
        stepByIndex.aiDetectedMissing++
      }

      if (isStepWithoutDeviations) {
        stepByIndex.notDeviated++
        stepByIndex.aiDetectedNotDeviated++
      }
    } else {
      normalisedStepsArray.push({
        name: `${numberMacro as number}. ${friendlyNames[numberMacro as number - 1]}`,
        stepId: `${numberMacro}`,
        numberMacro: numberMacro as number,

        aiDetectedOutOfOrder: isStepOutOfOrder ? 1 : 0,
        outOfOrder: isStepOutOfOrder ? 1 : 0,

        aiDetectedMissing: isStepMissing ? 1 : 0,
        missing: isStepMissing ? 1 : 0,

        aiDetectedNotDeviated: isStepWithoutDeviations ? 1 : 0,
        notDeviated: isStepWithoutDeviations ? 1 : 0,
      })
    }
  }

  return normalisedStepsArray
}
